<template>
  <section id="landing">
    <!-- Hero -->
    <div class="hero-section">
      <v-img class="hero-image" src="../../assets/img/hero.jpeg" alt="Maridela" />
    </div>

    <!-- How it works -->
    <v-container>
      <p class="text-h4 font-weight-bold text-center my-16">Πώς λειτουργεί;</p>
      <p class="text-h5 text-center text--secondary">Δεν χρειάζεσαι εξοπλισμό. Η θέληση, η επιμονή και το πρόγραμμα αρκεί!</p>
      <v-row class="my-16">
        <v-col cols="12" lg="3" class="d-flex flex-column align-center">
          <v-icon x-large>fas fa-file-signature</v-icon>
          <h2 class="mt-6">Κάνε <span class="mouse-pointer text-decoration-underline" @click="onRegister">εγγραφή</span></h2>
        </v-col>

        <v-col cols="12" lg="3" class="d-flex flex-column align-center">
          <v-icon x-large>fas fa-running</v-icon>
          <h2 class="mt-6">Γυμνάσου</h2>
        </v-col>

        <v-col cols="12" lg="3" class="d-flex flex-column align-center">
          <v-icon x-large>fas fa-weight</v-icon>
          <h2 class="mt-6">Δες αποτέλεσμα</h2>
        </v-col>

        <v-col cols="12" lg="3" class="d-flex flex-column align-center">
          <v-icon x-large>fas fa-laugh-beam</v-icon>
          <h2 class="mt-6">Χαμογέλα</h2>
        </v-col>
      </v-row>
    </v-container>

    <!-- Break section -->

    <!-- Services -->
    <v-container class="mb-16">
      <p class="text-h4 font-weight-bold text-center my-16">Μαζί θα κάνουμε..</p>
      <v-row>
        <v-col cols="12" lg="4">
          <v-card>
            <v-img src="../../assets/img/endynamosi.jpeg" alt="ενδυνάμωση"></v-img>
            <v-card-title class="d-flex">
              <span class="text-subtitle-1">Ενδυνάμωση και Functional Training</span>
              <v-btn icon class="ml-auto" @click="onToggleCategoryDescription(0)">
                <v-icon>fas fa-chevron-{{ openedCategoryDescriptions[0] ? 'up' : 'down' }}</v-icon>
              </v-btn>
            </v-card-title>
            <v-expand-transition>
              <div v-if="openedCategoryDescriptions[0]">
                <hr>
                <p class="pa-4">
                  <span class="text-justify">
                    Είναι γνωστή ως «λειτουργική προπόνηση». Αντλεί στοιχεία από την καθημερινότητα και τα ενσωματώνει
                    σε ένα πρόγραμμα γυμναστικής το οποίο περιλαμβάνει ασκήσεις με βάρη, αντιστάσεις, συντονισμού,
                    ισορροπίας και ιδιοδεκτικότητας.
                  </span>
                  <br>
                  <br>
                  Είναι ιδανικό για:<br>
                  - Απώλεια λίπους<br>
                  - Φυσική κατάσταση<br>
                  - Αύξηση της δύναμης, της ισορροπίας και της ταχύτητας<br>
                  - Βελτίωση της καθημερινότητας
                </p>
              </div>
            </v-expand-transition>
          </v-card>
        </v-col>
        <v-col cols="12" lg="4">
          <v-card>
            <v-img src="../../assets/img/hiit.jpeg" alt="hiit"></v-img>
            <v-card-title class="d-flex">
              <span class="text-subtitle-1">HIIT</span>
              <v-btn icon class="ml-auto" @click="onToggleCategoryDescription(1)">
                <v-icon>fas fa-chevron-{{ openedCategoryDescriptions[1] ? 'up' : 'down' }}</v-icon>
              </v-btn>
            </v-card-title>
            <v-expand-transition>
              <div v-if="openedCategoryDescriptions[1]">
                <hr>
                <p class="pa-4">
                  <span class="text-justify">
                    Το HIIT αποτελείται από σύντομες εκρήξεις έντονης άσκησης που εναλλάσονται με περιόδους ανάκαμψεις
                    χαμηλής έντασης.
                  </span>
                  <br>
                  <br>
                  Είναι ιδανικό για:<br>
                  - Απώλεια λίπους<br>
                  - Μυϊκή υπερτροφία<br>
                  - Βελτιώση της πρόσληψης οξυγόνου<br>
                  - Καύση περισσότερων θερμίδων
                </p>
              </div>
            </v-expand-transition>
          </v-card>
        </v-col>
        <v-col cols="12" lg="4">
          <v-card>
            <v-img src="../../assets/img/pilates.jpeg" alt="pilates"></v-img>
            <v-card-title class="d-flex">
              <span class="text-subtitle-1">Pilates</span>
              <v-btn icon class="ml-auto" @click="onToggleCategoryDescription(2)">
                <v-icon>fas fa-chevron-{{ openedCategoryDescriptions[2] ? 'up' : 'down' }}</v-icon>
              </v-btn>
            </v-card-title>
            <v-expand-transition>
              <div v-if="openedCategoryDescriptions[2]">
                <hr>
                <p class="pa-4">
                  <span class="text-justify">
                    Είναι μια σωματική και ψυχική προπόνηση η οποία επικεντρώνεται στην ισορροπία, την αναπνοή, τη ροή της
                    κίνησης και την συγκέντρωση.
                  </span>
                  <br>
                  <br>
                  Είναι ιδανικό για:<br>
                  - Όλους τους ασκουμένους<br>
                  - Εκγύμναση ολόκληρου του σώματος<br>
                  - Βελτίωση της ευλυγισίας<br>
                  - Βοηθά στην απώλεια βάρους<br>
                  - Αύξηση των επιπέδων ενέργειας
                </p>
              </div>
            </v-expand-transition>
          </v-card>
        </v-col>
<!--        <v-col cols="12" lg="4">-->
<!--          <v-card>-->
<!--            <v-img src="https://images.unsplash.com/photo-1565299543923-37dd37887442?h=694&q=80"></v-img>-->
<!--            <v-card-title class="d-flex">-->
<!--              <span class="text-subtitle-1">Healthy συνταγές</span>-->
<!--            </v-card-title>-->
<!--          </v-card>-->
<!--        </v-col>-->
      </v-row>
    </v-container>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {

  data () {
    return {
      openedCategoryDescriptions: [0, 0, 0, 0]
    };
  },

  computed: {
    ...mapGetters('auth', {
      isAuthenticated: 'isAuthenticated'
    })
  },

  methods: {

    onToggleCategoryDescription (index) {
      this.$set(this.openedCategoryDescriptions, index, this.openedCategoryDescriptions[index] ? 0 : 1);
    },

    onRegister () {
      this.$router.push({ name: this.isAuthenticated ? 'App:Dashboard' : 'Authentication:Register' });
    }

  }
};
</script>

<style scoped>
.hero-image {
  height: auto;
  width: 100%;
}
</style>
